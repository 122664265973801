import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog, SignInPage } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { githubAuthApiRef } from '@backstage/core-plugin-api';

import { KibanInfoPage } from './components/kiban';

// Theme related imports
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import LightIcon from '@material-ui/icons/WbSunny';
import {
  createTheme,
  genPageTheme,
  lightTheme,
  darkTheme,
  shapes
} from '@backstage/theme';

const nttPrimaryColours = {
  blue: '#03163D',
  grey: '#9D9D9C',
  white: '#ECF3FF'
};
// Public sector colour palette taken from:
// https://sp-uk-info.nttdata-emea.com/NTT%20DATA%20UK%20Brand%20Refresh/NTT%20DATA%20Guidelines%20final.pdf
const nttSecondaryColours = {
  dark: '#606E9F',
  midDark: '#7283BC',
  midLight: '#96A1CB',
  light: '#BAC2DF'
};

const nttPalette = {
  primary: {
    main: nttPrimaryColours.blue,
  },
  secondary: {
    main: nttSecondaryColours.dark,
  },
  navigation: {
    background: nttPrimaryColours.blue,
    indicator: nttPrimaryColours.grey,
    color: nttPrimaryColours.white,
    selectedColor: nttSecondaryColours.midLight,
  }
}

const nttPageTheme = genPageTheme({
  colors: [nttSecondaryColours.dark, nttSecondaryColours.midDark, nttSecondaryColours.midLight, nttSecondaryColours.light],
  shape: shapes.round
});

const nttLightTheme = createTheme({
  palette: {
    ...lightTheme.palette,
    ...nttPalette
  },
  fontFamily: 'Helvetica Neue',
  defaultPageTheme: 'home',
  pageTheme: {
    home: nttPageTheme,
    documentation: nttPageTheme,
    tool: nttPageTheme,
    service: nttPageTheme,
    website: nttPageTheme,
    library: nttPageTheme,
    other: nttPageTheme,
    app: nttPageTheme,
    apis: nttPageTheme,
  }
});

const nttDarkTheme = createTheme({
  palette: {
    ...darkTheme.palette,
    ...nttPalette,
    primary: {
      main: nttSecondaryColours.midDark,
      dark: nttPrimaryColours.blue
    }
  },
  fontFamily: 'Helvetica Neue',
  defaultPageTheme: 'home',
  pageTheme: {
    home: nttPageTheme,
    documentation: nttPageTheme,
    tool: nttPageTheme,
    service: nttPageTheme,
    website: nttPageTheme,
    library: nttPageTheme,
    other: nttPageTheme,
    app: nttPageTheme,
    apis: nttPageTheme,
  }
});

const app = createApp({
  apis,
  themes: [{
    id: 'ntt-light',
    title: 'NTT Light Theme',
    variant: 'light',
    icon: <LightIcon />,
    Provider: ({ children }) => (
      <ThemeProvider theme={nttLightTheme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    ),
  }, {
    id: 'ntt-dark',
    title: 'NTT Dark Theme',
    variant: 'dark',
    icon: <LightIcon />,
    Provider: ({ children }) => (
      <ThemeProvider theme={nttDarkTheme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    ),
  }],
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        auto
        providers={[
          {
           id: 'github-auth-provider',
           title: 'GitHub',
           message: 'Sign in using GitHub',
           apiRef: githubAuthApiRef
          }
        ]}
      />
    ),
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage 
      groups={[
        {
          title: "Gitlab",
          filter: entity =>
            entity?.metadata?.tags?.includes('gitlab') ?? false,
        },
        {
          title: "Github",
          filter: entity =>
            entity?.metadata?.tags?.includes('github') ?? false,
        },
        {
          title: "ADO",
          filter: entity =>
            entity?.metadata?.tags?.includes('ado') ?? false,
        },
        {
          title: "Testing",
          filter: entity =>
            entity?.metadata?.tags?.includes('testing') ?? false,
        },
        {
          title: "Terraform",
          filter: entity =>
            entity?.metadata?.tags?.includes('terraform') ?? false,
        },
      ]}
    
    
    />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/what-is-kiban" element={<KibanInfoPage />} />
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
