import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: 'white',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className={classes.path} d="M17.1043 17.8399C16.993 17.639 10.0021 4.49617 9.14651 3.20228C8.72402 2.41407 8.07853 1.75773 7.28495 1.30944C6.49137 0.861156 5.5823 0.639334 4.66331 0.669748C2.52944 0.669748 0 1.5809 0 6.52843V23.1371H5.15671V9.28498C5.15671 8.29146 5.09113 6.81876 5.08072 6.52341C5.06927 6.27629 5.08071 6.04422 5.20874 5.9729C5.35655 5.89052 5.50542 6.02815 5.60639 6.21199C5.70736 6.39582 12.186 18.7621 13.5611 20.8527C13.9846 21.6405 14.6301 22.2969 15.4233 22.7462C16.2165 23.1955 17.125 23.4195 18.0443 23.3922C20.1781 23.3922 22.7097 22.47 22.7097 17.5245V0.910852H17.5499V14.7659C17.5499 15.7605 17.6154 17.2392 17.6279 17.5315C17.6404 17.7787 17.6279 18.0027 17.4988 18.082C17.3531 18.1614 17.2032 18.0228 17.1043 17.8399" />
    </svg>
  );
};

export default LogoIcon;
